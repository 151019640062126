import type { AppProps } from "next/app";
import { config } from "@fortawesome/fontawesome-svg-core";
import TagManager from "react-gtm-module";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "../styles/globals.css";
import { useEffect } from "react";
import { NextIntlProvider } from "next-intl";
import IntlPolyfills from "../components/IntlPolyfills";

config.autoAddCss = false;

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-KD9DSBN" });
  }, []);

  return (
    <NextIntlProvider messages={pageProps.messages}>
      <IntlPolyfills />
      <Component {...pageProps} />
    </NextIntlProvider>
  );
}

export default MyApp;
